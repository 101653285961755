export const state = () => ({
    loading: false,
    boLanguages: [],
    // cancel token
    cancelToken: null,
})

export const actions = {
    async boLanguages({ commit, state }) {
        commit('setLoading', true)
        if (state.cancelToken) {
            state.cancelToken.cancel('')
        }
        commit('setCancelToken', this.$axios.CancelToken.source())
        try {
            const response = await this.$axios.get(
                '/website-setting/languages',
                {
                    cancelToken: state.cancelToken.token,
                }
            )
            commit('boLanguages', response.data)
        } catch (error) {
        } finally {
            commit('setLoading', false)
        }
    },
    async createLanguage({ dispatch, rootGetters }, form) {
        try {
            await this.$axios.post('/website-setting/languages', form)
            dispatch('boLanguages', { is_active: true })
        } catch (error) {}
    },
    async updateBOLanguage({ dispatch, rootGetters }, data) {
        try {
            await this.$axios.put(`/website-setting/languages/${data.id}`, {
                is_active_bo: data.status,
            })
            dispatch('master/languages', true, { root: true })
            // dispatch('boLanguages', { is_active: true })
            return true
        } catch (error) {
            return false
        }
    },
    async updateLandingLanguage({ rootGetters, dispatch }, data) {
        try {
            await this.$axios.put(
                `/website-setting/languages/landing/${data.currency}`,
                { ...data.body }
            )
            dispatch('settings/currency', { is_active: true }, { root: true })
            return true
        } catch (error) {
            return false
        }
    },
    async updateDefaultCurrency({ dispatch }, currency) {
        try {
            await this.$axios.put(
                `/website-setting/languages/landing/set-default-currency/${currency}`
            )
            await dispatch(
                'settings/currency',
                { is_active: true },
                { root: true }
            )
            return true
        } catch (error) {
            return false
        }
    },
}

export const mutations = {
    setLoading: (state, v) => {
        state.loading = v
    },
    boLanguages: (state, v) => {
        state.boLanguages = v
    },
    updateBOLanguageLoading: (state, v) => {
        state.updateBOLanguageLoading = v
    },
    updateLandingLanguage: (state, v) => {
        state.updateLandingLanguage = v
    },
    setCancelToken: (state, data) => {
        state.cancelToken = data
    },
    setBoLanguageByStatusByCode: (state, { code, status }) => {
        const languages = JSON.parse(JSON.stringify(state.boLanguages))

        const updatedLanguages = languages.find((el) => el.code === code)
        updatedLanguages.is_active_bo = status

        state.boLanguages = languages
    },
}

export const getters = {
    searchLandingLanguages: (state) => (currencies, currency) => {
        if (!currency) return state.boLanguages

        const selectedCurrency = currencies.find((el) => el.code === currency)
        if (!selectedCurrency) return state.boLanguages

        const languages = state.boLanguages
            .filter((el) =>
                selectedCurrency.supported_languages.some(
                    (sEl) => sEl.language === el.code
                )
            )
            .map((data) => {
                const langStatus = selectedCurrency.supported_languages.find(
                    (el) => el.language === data.code
                )
                return {
                    ...data,
                    is_active_bo: langStatus.is_active,
                    is_default: langStatus.is_default,
                }
            })

        return languages.sort((a, b) => {
            return b.is_active && b.is_default
                ? 1
                : a.is_active && a.is_default
                ? -1
                : b.is_active - a.is_active
        })
    },
    gethBOLanguages: (state) => state.boLanguages,
    getLanguageByCode: (state) => (code) => {
        return state.boLanguages.find((el) => el.code === code) ?? {}
    },
}
