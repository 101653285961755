export const state = () => ({
    isActive: false,
    ipWhiteSettingLoading: false,
    ipWhitelist: [],
    loading: false,
})

export const actions = {
    // Just to get the website setting IP whitelist status
    async getIpWhiteSetting({ commit }, params) {
        commit('setWhiteSettingLoading', true)
        await this.$axios
            .get('/website-setting/ip-whitelist-setting', { params })
            .then((data) => {
                commit('setIsActive', data?.data?.value?.is_active)
            })
            .catch((_) => {})
        commit('setWhiteSettingLoading', false)
    },
    async getIpWhiteSettingList({ commit }, params) {
        commit('setLoading', true)
        await this.$axios
            .get('/website-setting/ip-whitelist-setting/all-ip-whitelists', {
                params,
            })
            .then((data) => {
                commit('setIpWhiteList', data?.data)
            })
            .catch((_) => {})
        commit('setLoading', false)
    },

    async setIpWhiteListSettingStatus({ commit }, status) {
        const response = await this.$axios
            .put('/website-setting/ip-whitelist-setting/status', {
                is_active: status,
            })
            .then((data) => {
                return true
            })
            .catch((_) => {
                commit('setIsActive', !status)
                return false
            })
        return response
    },
    async deleteIpWhitelist({ _ }, id) {
        const response = await this.$axios
            .delete(
                `/website-setting/ip-whitelist-setting/delete-whitelist-ip/${id}`
            )
            .then((data) => {
                return true
            })
            .catch((_) => {
                return false
            })
        return response
    },
    async createIpWhiteList({ _ }, data) {
        const response = await this.$axios
            .post(
                `/website-setting/ip-whitelist-setting/add-whitelist-ip`,
                data
            )
            .then((data) => {
                return data.data
            })
            .catch((_) => {
                return false
            })
        return response
    },
}

export const mutations = {
    setLoading: (state, v) => {
        state.loading = v
    },
    setIsActive: (state, v) => {
        state.isActive = v
    },
    setWhiteSettingLoading: (state, v) => {
        state.ipWhiteSettingLoading = v
    },
    setIpWhiteList: (state, v) => {
        state.ipWhitelist = v.sort(
            (a, b) => new Date(b.created_at) - new Date(a.created_at)
        )
    },
}

export const getters = {
    ipWhitelist: (state) => (search) => {
        if (!search) {
            return state.ipWhitelist
        }
        return state.ipWhitelist.filter((el) => el.ip?.includes(search))
    },
}
